import * as FullStory from '@fullstory/browser'
import moment from 'moment'

export default {
  methods: {
    identifyUser(userData) {
      if (!userData) return
      let fullName = userData.first_name + ' ' + userData.last_name
      try {
        FullStory.identify(userData.customer_id, {
          displayName: fullName,
          email: userData.email || '',
        })
      } catch (error) {
        console.log(error)
      }
    },
    orderPlacedEvent() {
      if (!this.orderData) return
      try {
        FullStory.event('Order Placed', {
          orderId: this.orderData.order.order_id,
          customerEmail: this.orderData.customer.email,
          customerFirstName: this.orderData.customer.first_name,
          customerLastName: this.orderData.customer.last_name,
          customerPhone: this.orderData.customer.phone,
          orderTotal: this.orderData.order.charges.total_cents,
          date: moment(this.orderData.order.created_at).format('YYYY-MM-DD'),
          time: moment(this.orderData.order.created_at).format('HH:mm'),
          storeId: this.orderData.restaurant.store_id,
          chainName: this.storeConfig.chain_display_name || '',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
