import axios from 'axios'
import endpoints from './endpoints.js'
import { createRequestData, url, replaceSpace } from './constants.js'

// get payment methods
const getPaymentMethods = async (storeId, payload) => {
  let requestData = createRequestData(
    endpoints.STORES.PAYMENT_METHODS.replace(':storeId', replaceSpace(storeId)),
    'GET'
  )
  if (payload) {
    requestData = { ...payload, ...requestData }
  }
  return await axios.post(url, requestData)
}

// get stores
const getStores = async (payload) => {
  let requestData = createRequestData(endpoints.STORES.STORES, 'GET')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get single store
const getStore = async (storeId, payload) => {
  let requestData = {}
  if (storeId) {
    requestData = createRequestData(endpoints.STORES.STORE.replace(':storeId', replaceSpace(storeId)), 'GET')
  } else {
    requestData = createRequestData(endpoints.STORES.STORE_NO_ID, 'GET')
  }
  if (payload) {
    requestData = { ...payload, ...requestData }
  }
  return await axios.post(url, requestData)
}

// get configuration
const getConfiguration = async () => {
  let requestData = createRequestData(endpoints.STORES.CONFIG, 'GET')
  return await axios.post(url, requestData)
}

// get branding
const getBranding = async () => {
  let requestData = createRequestData(endpoints.STORES.BRANDING, 'GET')
  return await axios.post(url, requestData)
}

// get app branding
const getAppBranding = async () => {
  let requestData = createRequestData(endpoints.STORES.APP_BRANDING, 'GET')
  return await axios.post(url, requestData)
}

export default {
  getPaymentMethods,
  getStores,
  getStore,
  getConfiguration,
  getBranding,
  getAppBranding,
}
