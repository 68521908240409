<template>
  <div @click.self="closeModal" class="modalContainer">
    <div class="modalWrapper">
      <div @click="closeModal" class="close">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
            fill="black"
            stroke="black"
          />
        </svg>
      </div>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <div v-if="loading" class="loaderWrapper">
        <div class="loader"></div>
      </div>
      <div class="contentWrapper" v-if="!loading">
        <h1>{{ header }}</h1>
        <h3>{{ message }}</h3>
        <button @click="closeModal">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import customersApi from '@/api/customersApi'

export default {
  data() {
    return {
      message: 'You’ve successfully earned points through your purchase',
      header: 'Congratulations!',
      loading: true,
    }
  },
  computed: {
    ...mapState(['branding', 'customerData', 'storeConfig', 'cookies', 'selectedStore', 'copperDetails']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  watch: {
    selectedStore(newVal, oldVal) {
      if (oldVal == null && newVal != null) {
        this.processCopperPoints()
      }
    },
  },
  methods: {
    ...mapMutations(['SET_COPPER_DETAILS']),
    closeModal() {
      this.$emit('closeModal')
    },
    processCopperPoints() {
      let customerId = localStorage.getItem('customerId')
      let chainName = localStorage.getItem('chainName')
      let payload = {
        params: {
          url: this.branding.api_base_url + '/incoming/eef3acaf-170f-4270-8235-4b9e953eff20?synchronous=1',
          method: 'POST',
        },
        headers: {
          service_id: '38e738f2-ac3c-11ed-8a64-001a6436ce70',
          'Copper-Restaurant-External-ID': 1,
        },
        cookies: this.cookies,
        event_type: 'order_redeem_request',
        chain: chainName,
        copperItem_id: 'cd98b5a0-5698-4eb8-a530-b89906b31536',
        ticket_id: this.copperDetails.ticket,
        store_id: this.$route.params.storeId,
        loyalty_identifier: customerId,
        rest_id: this.selectedStore.restaurant_id,
      }
      customersApi
        .collectLoyaltyInStore(payload)
        .then((response) => {
          this.loading = false
          if (response?.data?.data?.error) {
            this.header = 'Error'
            this.message = response?.data?.data?.error?.message
            return
          } else {
            this.header = 'Success'
            this.message = 'You’ve successfully earned points through your purchase'
          }
          this.SET_COPPER_DETAILS(null)
        })
        .catch((error) => {
          this.loading = false
          this.header = 'Error'
          this.message = error.response?.data?.data
          this.SET_COPPER_DETAILS(null)
          console.log(error)
        })
    },
  },
  mounted() {
    if (this.selectedStore) {
      this.processCopperPoints()
    }
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    })
  },
  beforeUnmount() {
    localStorage.removeItem('copperDetails')
    document.removeEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    })
  },
}
</script>

<style src="../../../assets/css/Modals/StoreModals/CopperLoyaltyModal.css" scoped></style>
