import { Loader } from '@googlemaps/js-api-loader'

export default {
  data() {
    return {
      loader: new Loader({
        apiKey:
          process.env.VUE_APP_ENVIRONMENT == 'LOCAL'
            ? process.env.VUE_APP_MAPS_API_KEY
            : 'AIzaSyAQ8ZhIsMCzQvCw94z1vNZ0j3SBpsRgrmM',
        version: 'weekly',
        libraries: ['places'],
      }),
    }
  },
}
