<template>
  <nav v-if="$route.name != 'ConfirmationView'">
    <transition name="fade">
      <LoginModal
        v-if="loginModalIsOpen"
        @closeModal="toggleLoginModal"
        @signUpClicked="toggleSignUpModal"
        @forgotPasswordClicked="toggleForgotPasswordModal"
        @toggleCopperInfo="toggleCopperLoyaltyModal"
      />
    </transition>
    <transition name="fade">
      <SignUpModal
        v-if="signUpModalIsOpen"
        @closeModal="toggleSignUpModal"
        @loginClicked="toggleLoginModal"
        @accountCreated="togglePhoneVerificationModal"
        @openVerifyEmailModal="toggleEmailVerificationModal"
        @toggleCopperInfo="toggleCopperLoyaltyModal"
      />
    </transition>
    <transition name="fade">
      <ForgotPasswordModal
        v-if="forgotPasswordModalIsOpen"
        @closeModal="toggleForgotPasswordModal"
        @signUpClicked="toggleSignUpModal"
        @backToLogin="toggleLoginModal"
      />
    </transition>
    <transition name="fade">
      <DateAndTimeModal v-if="dateAndTimeModalIsOpen" @closeModal="toggleDateAndTimeModal" />
    </transition>
    <transition name="fade">
      <PhoneVerificationModal v-if="phoneVerificationModalIsOpen" @closeModal="togglePhoneVerificationModal" />
    </transition>
    <transition name="fade">
      <EmailVerificationModal v-if="emailVerificationModalIsOpen" @closeModal="toggleEmailVerificationModal" />
    </transition>
    <transition name="fade">
      <CopperLoyaltyModal v-if="copperLoyaltyModalIsOpen" @closeModal="toggleCopperLoyaltyModal" />
    </transition>
    <div
      :class="{
        navOnMenu:
          $route.name == 'MenuView' ||
          $route.name == 'CheckoutView' ||
          $route.name == 'ConfirmationView' ||
          $route.name == 'ContentHubView',
      }"
      class="navWrapper"
    >
      <div class="left">
        <img class="logo" @click="logoClicked" :src="getLogo" alt="companyLogo" />
      </div>
      <div class="right" ref="cartButton">
        <a v-if="loggedIn && customerData?.is_mgmt" @click="jumpToAdmin" class="navLink jump" href="javascript:;">
          <p class="navLinkText">{{ windowWidth >= 900 ? 'Jump To Admin' : 'Admin' }}</p>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.33008 7.43164C9.33008 7.62305 9.26562 7.7832 9.13672 7.91211C9.00781 8.04102 8.85742 8.10547 8.68555 8.10547C8.50977 8.10547 
                8.36133 8.04102 8.24023 7.91211C8.11914 7.7793 8.05859 7.625 8.05859 7.44922V5.25781L8.15234 2.84375L7.20312 3.91016L1.61914 
                9.49414C1.48242 9.62695 1.32812 9.69336 1.15625 9.69336C1.03906 9.69336 0.929688 9.66211 0.828125 9.59961C0.730469 9.54102 0.650391 
                9.46094 0.587891 9.35938C0.525391 9.25781 0.494141 9.15039 0.494141 9.03711C0.494141 8.86914 0.564453 8.71484 0.705078 8.57422L6.27734 
                2.98438L7.34375 2.04688L4.82422 2.13477H2.73828C2.5625 2.13477 2.41016 2.07422 2.28125 1.95312C2.15234 1.82812 2.08789 1.67969 2.08789 
                1.50781C2.08789 1.33594 2.15039 1.18555 2.27539 1.05664C2.40039 0.927734 2.56055 0.863281 2.75586 0.863281H8.63867C8.84961 0.863281 
                9.01562 0.927734 9.13672 1.05664C9.26172 1.18164 9.32422 1.3457 9.32422 1.54883L9.33008 7.43164Z"
              fill="#414143"
            />
          </svg>
        </a>
        <a
          v-if="branding?.display_options?.display_content_hub"
          @click="goToContentHub"
          class="navLink"
          href="javascript:;"
        >
          <svg
            class="notSelectedContentHub"
            v-if="$route.name !== 'ContentHubView'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.672 18.672"
          >
            <path
              id="notSelected"
              d="M4.676,10.371a1.969,1.969,0,0,1-1.45-.487,2.067,2.067,0,0,1-.487-1.5V3.85a2.03,2.03,0,0,1,.487-1.491,1.968,
                1.968,0,0,1,1.45-.487H9.3a1.969,1.969,0,0,1,1.45.487,2.03,2.03,0,0,1,.487,1.491V8.383a2.067,2.067,0,0,1-.487,1.5,1.969,1.969,
                0,0,1-1.45.487Zm.02-1.43H9.271a.514.514,0,0,0,.4-.142.564.564,0,0,0,.142-.416V3.85a.529.529,0,0,0-.142-.406.514.514,0,
                0,0-.4-.142H4.7a.514.514,0,0,0-.4.142.553.553,0,0,0-.132.406V8.383A.59.59,0,0,0,4.3,8.8.514.514,0,0,0,4.7,8.941Zm10.163,
                1.43A1.984,1.984,0,0,1,13.4,9.884a2.066,2.066,0,0,1-.487-1.5V3.85A2.029,2.029,0,0,1,13.4,2.359a1.984,1.984,0,0,1,
                1.461-.487h4.615a1.969,1.969,0,0,1,1.45.487,2.029,2.029,0,0,1,.487,1.491V8.383a2.066,2.066,0,0,1-.487,1.5,1.969,
                1.969,0,0,1-1.45.487Zm.02-1.43h4.574a.5.5,0,0,0,.4-.142.59.59,0,0,0,.132-.416V3.85a.553.553,0,0,0-.132-.406.5.5,
                0,0,0-.4-.142H14.88a.528.528,0,0,0-.406.142.553.553,0,0,0-.132.406V8.383a.59.59,0,0,0,.132.416A.528.528,0,0,0,
                14.88,8.941Zm-10.2,11.6a1.968,1.968,0,0,1-1.45-.487,2.029,2.029,0,0,1-.487-1.491V14.022a2.014,2.014,0,0,1,
                .487-1.481,1.944,1.944,0,0,1,1.45-.5H9.3a1.944,1.944,0,0,1,1.45.5,2.014,2.014,0,0,1,.487,1.481v4.544a2.03,
                2.03,0,0,1-.487,1.491,1.968,1.968,0,0,1-1.45.487Zm.02-1.43H9.271a.514.514,0,0,0,.4-.142.529.529,0,0,0,
                .142-.406V14.033a.543.543,0,0,0-.142-.416.514.514,0,0,0-.4-.142H4.7a.514.514,0,0,0-.4.142.568.568,0,0,
                0-.132.416v4.534a.553.553,0,0,0,.132.406A.514.514,0,0,0,4.7,19.114Zm10.163,1.43a1.983,1.983,0,0,1-1.461-.487,
                2.029,2.029,0,0,1-.487-1.491V14.022a2.014,2.014,0,0,1,.487-1.481,1.958,1.958,0,0,1,1.461-.5h4.615a1.944,1.944,0,0,1,1.45.5,2.014,
                2.014,0,0,1,.487,1.481v4.544a2.029,2.029,0,0,1-.487,1.491,1.968,1.968,0,0,1-1.45.487Zm.02-1.43h4.574a.5.5,0,0,0,.4-.142.553.553,
                0,0,0,.132-.406V14.033a.568.568,0,0,0-.132-.416.5.5,0,0,0-.4-.142H14.88a.529.529,0,0,0-.406.142.568.568,0,0,
                0-.132.416v4.534a.553.553,0,0,0,.132.406A.528.528,0,0,0,14.88,19.114Z"
              transform="translate(-2.739 -1.872)"
            />
          </svg>
          <svg class="selectedContentHub" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.672 18.672">
            <path
              id="selected"
              d="M3.01,9.275a2.616,2.616,0,0,1-1.9-.615A2.647,2.647,0,0,1,.49,6.736V3.162a2.611,2.611,0,0,1,.615-1.914A2.591,2.591,0,0,1,
              3.01.623H6.633a2.556,2.556,0,0,1,1.895.625,2.611,2.611,0,0,1,.615,1.914V6.736A2.647,2.647,0,0,1,8.527,8.66a2.581,2.581,0,0,
              1-1.895.615Zm10.02,0a2.616,2.616,0,0,1-1.9-.615,2.647,2.647,0,0,1-.615-1.924V3.162a2.611,2.611,0,0,1,.615-1.914,2.591,2.591,
              0,0,1,1.9-.625h3.623a2.556,2.556,0,0,1,1.895.625,2.611,2.611,0,0,1,.615,1.914V6.736a2.647,2.647,0,0,1-.615,1.924,2.581,2.581,
              0,0,1-1.895.615ZM3.01,19.295a2.591,2.591,0,0,1-1.9-.625A2.611,2.611,0,0,1,.49,16.756V13.172a2.6,2.6,0,0,1,.615-1.9,2.591,2.591,
              0,0,1,1.9-.625H6.633a2.555,2.555,0,0,1,1.895.625,2.6,2.6,0,0,1,.615,1.9v3.584a2.611,2.611,0,0,1-.615,1.914,2.555,2.555,0,0,
              1-1.895.625Zm10.02,0a2.591,2.591,0,0,1-1.9-.625,2.611,2.611,0,0,1-.615-1.914V13.172a2.6,2.6,0,0,1,.615-1.9,2.591,2.591,0,0,1,
              1.9-.625h3.623a2.556,2.556,0,0,1,1.895.625,2.6,2.6,0,0,1,.615,1.9v3.584a2.611,2.611,0,0,1-.615,1.914,2.556,2.556,0,0,1-1.895.625Z"
              transform="translate(-0.49 -0.623)"
              fill="#942f39"
            />
          </svg>
          <p
            class="navLinkText"
            :style="{ color: $route.name == 'ContentHubView' ? 'var(--primary-color)' : 'var(--text-color)' }"
          >
            Content Hub
          </p>
        </a>
        <a v-if="!loggedIn && $route.name != 'LoginView'" @click="toggleLoginModal" class="navLink" href="javascript:;" data-cy="login-modal-button">
          <img style="max-height: 24px" :src="branding.display_options?.icons.profile" alt="profile" />
          <p class="navLinkText">Login</p>
        </a>
        <a
          v-if="loggedIn && customerData"
          @click="goToProfilePage"
          class="navLink"
          href="javascript:;"
          ref="profileDropdown"
          data-cy="profile-button"
        >
          <img style="max-height: 24px" :src="branding.display_options?.icons.profile" alt="profile" />
          <p class="navLinkText">Profile</p>
          <div v-if="profileDropdownIsVisible" class="profileDropdown">
            <div @click="openProfile('profile')" class="dropdownLink">
              <a>Profile</a>
            </div>
            <div
              v-if="
                (storeConfig.require_phone_verification && customerData.is_phone_verified) ||
                !storeConfig.require_phone_verification &&
                addressesIsVisible
              "
              @click="openProfile('addresses')"
              class="dropdownLink"
            >
              <a>Addresses</a>
            </div>
            <div
              v-if="
                ((customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                  !storeConfig.require_phone_verification) &&
                branding &&
                branding.display_options?.display_rewards_page
              "
              @click="openProfile('rewards')"
              class="dropdownLink"
            >
              <a>Rewards</a>
            </div>
            <div
              v-if="
                (storeConfig.require_phone_verification && customerData.is_phone_verified) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('favoriteItems')"
              class="dropdownLink"
            >
              <a>Favorite Items</a>
            </div>
            <div
              v-if="!customerData.is_phone_verified && storeConfig.require_phone_verification"
              @click="openProfile('verifyPhone')"
              class="dropdownLink"
            >
              <a>Verify Phone</a>
            </div>
            <div
              v-if="!customerData.is_verified && storeConfig.require_email_verification"
              @click="openProfile('verifyEmail')"
              class="dropdownLink"
            >
              <a>Verify Email</a>
            </div>
            <div
              v-if="
                (customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                (!storeConfig.require_phone_verification && manageCardsIsVisible)
              "
              @click="openProfile('manageCards')"
              class="dropdownLink"
            >
              <a>Manage Cards</a>
            </div>
            <div
              v-if="
                ((customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                  !storeConfig.require_phone_verification) &&
                storeConfig.gift_card_purchasing &&
                storeConfig.allow_gift_card_purchase
              "
              @click="openProfile('giftCards')"
              class="dropdownLink"
            >
              <a>Gift Cards</a>
            </div>
            <div
              v-if="
                (customerData.is_phone_verified && storeConfig.require_phone_verification) ||
                !storeConfig.require_phone_verification
              "
              @click="openProfile('orderHistory')"
              class="dropdownLink"
            >
              <a>Order History</a>
            </div>

            <div
              v-if="branding.external_links && branding.external_links.length > 0"
              @click="openProfile('externalLinks')"
              class="dropdownLink"
            >
              <a>External Links</a>
            </div>

            <a @click="logOut" class="logOut" href="javascript:;">
              <p>LOG OUT</p>
            </a>
          </div>
        </a>
        <button
          v-if="
            $route.name == 'ContentHubView' ||
            $route.name == 'AccountView' ||
            $route.name == 'ProfileView' ||
            $route.name == 'AddressesView' ||
            $route.name == 'OrderHistoryView' ||
            $route.name == 'ExternalLinksView' ||
            $route.name == 'ManageCardsView' ||
            $route.name == 'GiftCardsView' ||
            $route.name == 'RewardsView' ||
            $route.name == 'FavoriteItemsView'
          "
          class="orderNow"
          @click="orderNowClicked"
        >
          <svg
            class="orderNowIcon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.534307 1.50149C0.210484 1.55627 -0.0633371 1.95883 0.0128565 2.26373C0.077145 2.51384 0.193817 2.67105 0.396206 2.78063L0.500972 
                2.83541H2.02723H3.55348L3.57967 2.95928C3.60825 3.10458 3.6511 3.38566 3.68206 3.65721C3.69396 3.76201 3.71301 3.89541 3.72492 3.95496C3.7392 
                4.01451 3.76063 4.1479 3.77492 4.25271C3.78921 4.35752 3.81778 4.55046 3.83445 4.68147C3.85349 4.81248 3.87969 5.01733 3.89397 5.13882C3.90826 
                5.2603 3.92969 5.38654 3.94159 5.41989C3.95588 5.45562 3.9654 5.53185 3.9654 5.5914C3.9654 5.65095 3.98207 5.77005 4.00112 5.85818C4.03922 
                6.03207 4.07731 6.30124 4.10827 6.57993C4.11779 6.68236 4.13922 6.81575 4.15351 6.87768C4.1797 6.99678 4.21303 7.24213 4.24875 7.55656C4.26065 
                7.65898 4.28208 7.79237 4.29637 7.85431C4.32256 7.97341 4.35828 8.23781 4.39637 8.57606C4.40828 8.68801 4.42733 8.80473 4.43923 8.83808C4.45114 
                8.87143 4.46781 8.97623 4.47495 9.07151C4.48447 9.16679 4.49876 9.28828 4.51305 9.34544C4.53447 9.44311 4.56781 9.67893 4.60829 10.0243C4.62019 
                10.1196 4.63924 10.2482 4.65353 10.3102C4.67972 10.4293 4.7202 10.7175 4.75115 11.0009C4.76306 11.0962 4.7821 11.2249 4.79639 11.2868C4.8202 
                11.3964 4.85354 11.6369 4.89401 11.9776C4.90592 12.0728 4.92497 12.2015 4.93925 12.2634C4.95116 12.3253 4.97259 12.4349 4.98687 12.5064C5.01783 
                12.6826 5.04878 12.8065 5.09402 12.9351C5.14641 13.0828 5.34879 13.483 5.37261 13.483C5.38213 13.483 5.39403 13.4997 5.3988 13.5187C5.41785 
                13.6116 5.73929 13.9332 5.90596 14.0261C5.94406 14.0475 5.9893 14.0809 6.00835 14.0976C6.02501 14.1118 6.05835 14.1261 6.08216 14.1261C6.10359 
                14.1261 6.13216 14.1357 6.14645 14.15C6.17978 14.1833 6.46075 14.2691 6.53932 14.2691C6.57504 14.2691 6.63218 14.2786 6.6679 14.2929C6.74647 
                14.3238 17.2707 14.3262 17.3088 14.2976C17.3231 14.2857 17.3636 14.2691 17.3993 14.2595C17.4803 14.2405 17.6826 14.0475 17.7398 13.9356C17.7612 
                13.8903 17.7874 13.776 17.7922 13.6831C17.8041 13.5402 17.7969 13.4973 17.7446 13.3853C17.6541 13.1876 17.5564 13.0995 17.2993 12.9852C17.2564 
                12.9661 15.8088 12.9566 12.0848 12.9518L6.92981 12.947L6.82505 12.8899C6.50122 12.7088 6.38217 12.4349 6.29645 11.6488C6.28455 11.5369 6.26312 
                11.4106 6.24883 11.3678C6.23216 11.3201 6.23216 11.2773 6.24407 11.2653C6.25598 11.2534 8.59893 11.2415 11.9062 11.2368C17.5779 11.2296 17.8041 
                11.2249 17.8803 11.1486C17.8946 11.1343 17.9231 11.1248 17.9446 11.1248C17.9827 11.1248 18.2993 10.9747 18.3589 10.9295C18.5398 10.7913 18.7779 
                10.5317 18.8875 10.3507C18.9637 10.2268 19.0851 9.9743 19.0851 9.94333C19.0851 9.92427 19.0946 9.89092 19.1065 9.86711C19.1684 9.75039 19.2279 
                9.50742 19.2756 9.17156C19.2899 9.0739 19.3137 8.94289 19.3256 8.88095C19.3399 8.81902 19.3613 8.69039 19.3732 8.59511C19.4327 8.11395 19.447 
                8.00914 19.4684 7.93291C19.4803 7.88765 19.5018 7.76855 19.5113 7.67089C19.547 7.38743 19.5851 7.12065 19.6113 6.99202C19.6256 6.92532 19.6446 
                6.79669 19.6565 6.70618C19.6923 6.40843 19.7304 6.1607 19.7542 6.04636C19.7684 5.98443 19.7875 5.8558 19.7994 5.76052C19.8113 5.66524 19.8304 
                5.53899 19.8446 5.47944C19.8589 5.41989 19.8685 5.31985 19.8708 5.25792C19.8708 5.1936 19.8827 5.12214 19.8946 5.09594C19.9089 5.07212 19.9304 
                4.95778 19.9423 4.84345C19.9566 4.72673 19.9756 4.56475 19.9875 4.479C20.0113 4.32655 19.9994 4.13123 19.9661 4.08597C19.9566 4.07168 19.9423 
                4.03356 19.9327 3.99545C19.9256 3.95972 19.9065 3.93114 19.8946 3.93114C19.8804 3.93114 19.8708 3.91684 19.8708 3.89779C19.8708 3.85491 19.5946 
                3.6453 19.5399 3.6453C19.5184 3.6453 19.4851 3.631 19.4708 3.61433C19.447 3.59289 17.966 3.58336 12.2634 3.5786L5.0845 3.57384V3.4595C5.08212 3.39757 
                5.07021 3.2999 5.05831 3.24035C5.04402 3.1808 5.02259 3.04265 5.01307 2.93307C5.00116 2.82112 4.97973 2.69964 4.96545 2.66153C4.95354 2.62103 4.94163 
                2.54957 4.94163 2.50193C4.94163 2.30661 4.78687 1.9231 4.64638 1.77065C4.59638 1.71349 4.5059 1.64679 4.44399 1.61821C4.38447 1.58962 4.31542 1.55627 
                4.28923 1.54436C4.26542 1.53245 4.11541 1.51101 3.95588 1.4991C3.60348 1.47528 0.686694 1.47528 0.534307 1.50149ZM18.4565 5.03639C18.4493 5.07688 
                18.4327 5.19598 18.4184 5.30079C18.4041 5.4056 18.3827 5.53899 18.3684 5.59854C18.3541 5.65809 18.3374 5.77481 18.3327 5.86056C18.3255 5.94632 
                18.3089 6.0416 18.297 6.07495C18.2851 6.10829 18.2636 6.22978 18.2517 6.34888C18.2374 6.4656 18.2112 6.67045 18.1922 6.80146C18.1731 6.93247 18.1446 
                7.13018 18.1303 7.24213C18.116 7.35408 18.0946 7.48271 18.0827 7.52797C18.0708 7.57323 18.0493 7.69709 18.0374 7.8019C18.0089 8.06154 17.9684 8.35215 
                17.9398 8.48078C17.9279 8.54033 17.9088 8.67372 17.8946 8.77853C17.8136 9.42643 17.7136 9.63843 17.4112 9.80517L17.2874 9.87425H11.6562H6.02501L5.99644 
                9.65987C5.97977 9.54077 5.95596 9.35974 5.94406 9.25731C5.92977 9.1525 5.91072 9.01911 5.89644 8.95956C5.87501 8.85713 5.84882 8.68086 5.79881 8.2783C5.78691 
                8.18541 5.76786 8.05678 5.75357 7.99246C5.74167 7.92577 5.71786 7.78285 5.70357 7.67089C5.68929 7.55894 5.66071 7.36599 5.64166 7.24213C5.625 7.11827 
                5.5988 6.93009 5.5869 6.82528C5.57499 6.72047 5.55357 6.58708 5.53928 6.52753C5.51309 6.41081 5.47499 6.14402 5.43927 5.84865C5.42737 5.74385 5.40832 
                5.61045 5.39642 5.5509C5.38213 5.49135 5.36308 5.36272 5.34879 5.26506C5.33451 5.1674 5.31784 5.05783 5.30832 5.0221L5.29403 4.9554L11.861 4.95064C15.4754 
                4.94587 18.4398 4.94825 18.4493 4.95302C18.4613 4.95778 18.4636 4.99351 18.4565 5.03639Z"
              fill="white"
            />
            <path
              d="M7.47739 15.6744C7.43929 15.6863 7.3631 15.7054 7.31072 15.7197C7.12738 15.7649 6.91308 15.8983 6.7345 16.0722C6.63926 16.1651 6.55116 16.2675 6.53688 
              16.2985C6.52259 16.3295 6.49164 16.389 6.46306 16.4343C6.38449 16.5653 6.34401 16.701 6.32496 16.8964C6.30353 17.106 6.32972 17.4776 6.36782 17.5157C6.38211 
              17.53 6.39401 17.5562 6.39401 17.5752C6.39401 17.661 6.56545 17.9373 6.70831 18.0874C6.80355 18.1827 6.98928 18.3137 7.12023 18.3756C7.17262 18.3994 
              7.23452 18.4304 7.25595 18.4399C7.3131 18.4685 7.62264 18.5185 7.73931 18.5185C7.87027 18.5161 8.16075 18.4709 8.2179 18.4447C8.24171 18.4304 8.306 18.4018 
              8.35838 18.3756C8.59411 18.266 8.88221 17.9897 9.00365 17.761C9.1227 17.5324 9.15603 17.3823 9.15603 17.0845C9.15603 16.7773 9.11079 16.5962 8.98222 
              16.3771C8.90364 16.2437 8.57029 15.9031 8.48458 15.865C8.45362 15.853 8.41315 15.8316 8.3941 15.8197C8.34886 15.7935 8.21314 15.7363 8.10837 15.6982C8.0036 
              15.6625 7.57263 15.6458 7.47739 15.6744Z"
              fill="white"
            />
            <path
              d="M15.5489 15.6744C15.5108 15.6863 15.4394 15.7054 15.3941 15.7173C15.3489 15.7292 15.2894 15.7482 15.2632 15.7625C14.9941 15.8959 14.9489 15.9269 
              14.7679 16.1151C14.6465 16.2437 14.4989 16.4724 14.475 16.5748C14.4655 16.6105 14.4465 16.6534 14.4322 16.6725C14.3965 16.7177 14.3965 17.4347 14.4322 
              17.5109C14.4465 17.5419 14.4846 17.6205 14.5131 17.6872C14.6584 18.004 14.9179 18.2446 15.3275 18.4423C15.3846 18.4685 15.6941 18.5185 15.8108 18.5185C15.9513 
              18.5185 16.1823 18.4828 16.2632 18.4494C16.468 18.3661 16.5847 18.3089 16.5847 18.2898C16.5847 18.2803 16.5989 18.2708 16.618 18.2708C16.6632 18.2708 17.0132 
              17.9206 17.0132 17.8754C17.0132 17.8563 17.0228 17.842 17.0323 17.842C17.0513 17.842 17.0799 17.7849 17.199 17.5085C17.2252 17.449 17.2347 17.337 17.2371 
              17.1036C17.2371 16.7392 17.199 16.5867 17.0466 16.3461C16.9751 16.2342 16.7847 16.0317 16.6442 15.915C16.5823 15.865 16.2942 15.722 16.2513 15.722C16.2323 
              15.722 16.1727 15.7054 16.1203 15.6863C16.0156 15.6506 15.6679 15.6434 15.5489 15.6744Z"
              fill="white"
            />
          </svg>
          <p class="navLinkText">Order Now</p>
        </button>
        <a
          v-if="$route.name == 'MenuView' || ($route.name == 'CheckoutView' && windowWidth <= 1000)"
          @click="toggleCartViewModal"
          class="navLink"
          href="javascript:;"
        >
          <img class="cartImage" :src="branding.display_options?.icons.cart" alt="cart" />
          <p
            v-if="orderData"
            :style="{
              color: branding.display_options?.icons.color_on_cart_icon
                ? branding.display_options?.icons.color_on_cart_icon
                : '#FFFFFF',
            }"
            class="cartQty navLinkText"
          >
            {{ orderData ? getItemTotal : 0 }}
          </p>
          <div
            v-if="itemToaster.show"
            class="itemToaster"
            :style="{ backgroundColor: branding.dark_mode_enabled ? 'var(--background-color)' : 'var(--text-color)' }"
          >
            <p>
              {{ itemToaster.message }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </nav>
  <div v-if="$route.name == 'ConfirmationView'" class="confirmationHeader"></div>
  <div
    v-if="
      !(
        $route.name == 'HomeView' ||
        $route.name == 'CheckoutView' ||
        $route.name == 'AccountView' ||
        $route.name == 'NotFoundView' ||
        $route.name == 'ConfirmationView' ||
        $route.name == 'ContentHubView' ||
        $route.name == 'ProfileView' ||
        $route.name == 'AddressesView' ||
        $route.name == 'OrderHistoryView' ||
        $route.name == 'ExternalLinksView' ||
        $route.name == 'ManageCardsView' ||
        $route.name == 'GiftCardsView' ||
        $route.name == 'RewardsView' ||
        $route.name == 'FavoriteItemsView' ||
        $route.name == 'LoginView' 
      ) &&
      dayAndTimeSelected &&
      selectedService &&
      selectedStore &&
      orderData
    "
    class="middle"
  >
    <div class="line">
      <svg width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.33496 15.0947C11.2168 15.0947 14.4321 11.8794 14.4321 7.99756C14.4321 4.11572 
                11.2095 0.900391 7.32764 0.900391C3.45312 0.900391 0.237793 4.11572 0.237793 7.99756C0.237793 
                11.8794 3.45312 15.0947 7.33496 15.0947ZM7.33496 13.8423C4.09766 13.8423 1.50488 11.2422 
                1.50488 7.99756C1.50488 4.75293 4.09033 2.15283 7.32764 2.15283C10.5723 2.15283 13.1797 4.75293 
                13.1797 7.99756C13.1797 11.2422 10.5796 13.8423 7.33496 13.8423ZM3.75342 8.78125H7.32764C7.61328 
                8.78125 7.84033 8.56152 7.84033 8.27588V3.64697C7.84033 3.36865 7.61328 3.14893 7.32764 3.14893C7.04932 
                3.14893 6.82959 3.36865 6.82959 3.64697V7.77783H3.75342C3.46777 7.77783 3.24805 7.99756 3.24805 
                8.27588C3.24805 8.56152 3.46777 8.78125 3.75342 8.78125Z"
          fill="#1400FF"
        />
      </svg>
      <p class="date">
        {{ selectedDay.formatted }} @ {{ selectedHour.formatted }}
        <span v-if="selectedHour.hour == 'ASAP'">({{ formatTime(this.orderData.order.dp_stamp) }})</span>
      </p>
    </div>
    <div class="line">
      <svg width="13" height="15" viewBox="0 0 13 15" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.265137 12.231C0.265137 13.6812 1.01221 14.4136 2.47705 14.4136H10.6289C11.896 14.4136 
                12.6357 13.6812 12.6357 12.231V5.06055C12.6357 3.61768 11.8887 2.87793 10.4312 2.87793H9.36182C9.3252 
                1.39844 8.04346 0.138672 6.4541 0.138672C4.85742 0.138672 3.57568 1.39844 3.53906 2.87793H2.47705C1.01221 
                2.87793 0.265137 3.61768 0.265137 5.06055V12.231ZM4.71826 2.87793C4.74756 1.98438 5.47998 1.24463 
                6.4541 1.24463C7.4209 1.24463 8.15332 1.98438 8.18262 2.87793H4.71826ZM1.46631 12.1577V5.14111C1.46631 
                4.43799 1.83984 4.0791 2.51367 4.0791H10.3872C11.061 4.0791 11.4419 4.43799 11.4419 
                5.14111V12.1577C11.4419 12.8608 11.061 13.2197 10.585 13.2197H2.51367C1.83984 13.2197 1.46631 
                12.8608 1.46631 12.1577Z"
          fill="#1400FF"
        />
      </svg>
      <p class="type">
        {{
          selectedService.display_name ? selectedService.display_name.toUpperCase() : selectedService.name.toUpperCase()
        }}
        <span class="typeIndicator">{{ selectedService.requires_address ? 'TO' : 'FROM' }}:</span>
      </p>
      <p class="addressInfo" v-if="!branding.display_options.hide_store_address">
        {{
          selectedService.requires_address && selectedAddress
            ? selectedAddress.address1 + ', ' + selectedAddress.state_code + ' ' + selectedAddress.postal_code
            : selectedStore.address1 + ', ' + selectedStore.state + ' ' + selectedStore.postal_code
        }}
      </p>
        <p v-if="selectedStore.subtitle">{{selectedStore.subtitle}}</p>
      <p v-else>
        {{selectedStore.name}}
      </p>
    </div>
    <a @click="toggleDateAndTimeModal" class="edit" href="javascript:;">
      <p>CHANGE</p>
    </a>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import moment from 'moment'

import LoginModal from '@/components/Modals/AuthenticationModals/LoginModal.vue'
import SignUpModal from '@/components/Modals/AuthenticationModals/SignUpModal.vue'
import ForgotPasswordModal from '@/components/Modals/AuthenticationModals/ForgotPasswordModal.vue'
import DateAndTimeModal from '@/components/Modals/StoreModals/DateAndTimeModal.vue'
import PhoneVerificationModal from '@/components/Modals/AuthenticationModals/PhoneVerificationModal.vue'
import EmailVerificationModal from '@/components/Modals/AuthenticationModals/EmailVerificationModal.vue'
import CopperLoyaltyModal from '@/components/Modals/StoreModals/CopperLoyaltyModal.vue'

import visibilityMixins from '@/mixins/visibilityMixins'

import customersApi from '@/api/customersApi'

export default {
  data() {
    return {
      loginModalIsOpen: false,
      signUpModalIsOpen: false,
      forgotPasswordModalIsOpen: false,
      dateAndTimeModalIsOpen: false,
      phoneVerificationModalIsOpen: false,
      emailVerificationModalIsOpen: false,
      profileDropdownIsVisible: false,
      cartViewIsOpen: false,
      windowWidth: window.innerWidth,
      copperLoyaltyModalIsOpen: false,
    }
  },
  components: {
    LoginModal,
    SignUpModal,
    ForgotPasswordModal,
    DateAndTimeModal,
    PhoneVerificationModal,
    EmailVerificationModal,
    CopperLoyaltyModal,
  },
  mixins: [visibilityMixins],
  watch: {
    // watch route changes
    $route() {
      setTimeout(() => {
        this.getCartPosition()
      }, 500)
    },
  },
  computed: {
    ...mapState([
      'loggedIn',
      'selectedDay',
      'selectedHour',
      'selectedService',
      'selectedStore',
      'dayAndTimeSelected',
      'customerData',
      'orderData',
      'phoneVerified',
      'branding',
      'cartIsOpen',
      'cookies',
      'storeConfig',
      'selectedAddress',
      'itemToaster',
    ]),
    getLogo() {
      return this.branding.logo_url
    },
    getItemTotal() {
      let itemQty = 0
      this.orderData.order.items.forEach((item) => {
        // Exclude auto items from the total
        if (!item.auto) itemQty += item.quantity
      })
      return itemQty
    },
  },
  methods: {
    ...mapMutations([
      'SET_LOGGED_IN',
      'SET_ORDER_DATA',
      'SET_DAY_AND_TIME_SELECTED',
      'SET_CUSTOMER_DATA',
      'SET_CART_IS_OPEN',
      'SET_COOKIES',
      'RESET_COOKIES',
      'SET_COPPER_DETAILS',
      'SET_CART_BUTTON_POSITION',
    ]),
    formatTime(time) {
      let asapTime = time.split(' ')[1]
      return moment(asapTime, 'HH:mm').format('h:mm A')
    },
    logoClicked() {
      if (
        this.branding?.display_options?.logo_redirect_url?.enabled &&
        this.branding?.display_options?.logo_redirect_url?.url != ''
      ) {
        window.open(this.branding.display_options?.logo_redirect_url?.url, '_self')
        return
      }
      this.$router.push({ name: 'HomeView' })
    },
    toggleLoginModal() {
      this.loginModalIsOpen = !this.loginModalIsOpen
    },
    toggleSignUpModal() {
      this.signUpModalIsOpen = !this.signUpModalIsOpen
    },
    toggleForgotPasswordModal() {
      this.forgotPasswordModalIsOpen = !this.forgotPasswordModalIsOpen
    },
    toggleDateAndTimeModal() {
      this.dateAndTimeModalIsOpen = !this.dateAndTimeModalIsOpen
    },
    togglePhoneVerificationModal() {
      this.phoneVerificationModalIsOpen = !this.phoneVerificationModalIsOpen
    },
    toggleEmailVerificationModal() {
      this.emailVerificationModalIsOpen = !this.emailVerificationModalIsOpen
    },
    toggleCartViewModal() {
      this.SET_CART_IS_OPEN(!this.cartIsOpen)
    },
    toggleCopperLoyaltyModal() {
      this.copperLoyaltyModalIsOpen = !this.copperLoyaltyModalIsOpen
    },
    goToProfilePage() {
      if (window.innerWidth <= 900) {
        this.profileDropdownIsVisible = !this.profileDropdownIsVisible
      } else {
        this.$router.push({ name: 'ProfileView' })
      }
    },
    goToContentHub() {
      this.$router.push({ name: 'ContentHubView' })
    },
    orderNowClicked() {
      if (this.orderData) {
        let storeId = localStorage.getItem('selectedStoreId')
        this.$router.push({ name: 'MenuView', params: { storeId: storeId } })
      } else {
        this.$router.push({ name: 'HomeView' })
      }
    },
    jumpToAdmin() {
      let cookies = JSON.stringify(this.cookies)
      let payload = {
        cookies: cookies,
        tenant: process.env.VUE_APP_TENANT,
        chainName: this.storeConfig.chain_display_name,
      }
      // base64 encode the string
      let encodedData = btoa(JSON.stringify(payload))
      let adminUrl = 'https://dashboard.lunchbox.io/autologin'
      // add the encoded string to the url and open the admin in a new tab
      let url = adminUrl + '?params=' + encodedData
      window.open(url, '_blank')
    },
    openProfile(page) {
      switch (page) {
        case 'profile':
          this.$router.push({ name: 'ProfileView' })
          break
        case 'addresses':
          this.$router.push({ name: 'AddressesView' })
          break
        case 'rewards':
          this.$router.push({ name: 'RewardsView' })
          break
        case 'favoriteItems':
          this.$router.push({ name: 'FavoriteItemsView' })
          break
        case 'verifyPhone':
          this.togglePhoneVerificationModal()
          break
        case 'verifyEmail':
          this.toggleEmailVerificationModal()
          break
        case 'manageCards':
          this.$router.push({ name: 'ManageCardsView' })
          break
        case 'giftCards':
          this.$router.push({ name: 'GiftCardsView' })
          break
        case 'orderHistory':
          this.$router.push({ name: 'OrderHistoryView' })
          break
        case 'externalLinks':
          this.$router.push({ name: 'ExternalLinksView' })
          break
        default:
          break
      }
    },
    logOut() {
      customersApi
        .logout()
        .then((response) => {
          if (response.data.data.ok == 'Logged out') {
            this.SET_LOGGED_IN(false)
            this.SET_ORDER_DATA(null)
            localStorage.removeItem('customerId')
            localStorage.removeItem('orderId')
            localStorage.removeItem('selectedTip')
            localStorage.removeItem('dayAndTimeSelected')
            localStorage.removeItem('auth')
            localStorage.removeItem('loggedIn')
            localStorage.removeItem('phoneVerified')
            localStorage.removeItem('newCardData')
            localStorage.removeItem('otherTipAmount')
            this.RESET_COOKIES()
            this.SET_DAY_AND_TIME_SELECTED(false)
            this.SET_CUSTOMER_DATA(null)
            this.$router.push({ name: 'HomeView' })
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCartPosition() {
      if (this.$route.name == 'ConfirmationView') return
      let cartButtonPosition = this.$refs.cartButton.getBoundingClientRect()
      this.SET_CART_BUTTON_POSITION({ top: cartButtonPosition.top + 'px', left: cartButtonPosition.right - 20 + 'px' })
    },
  },
  created() {
    if (localStorage.getItem('customerId')) {
      this.SET_LOGGED_IN(true)
    }

    // copper
    if (this.$route.query.merchant && this.$route.query.location && this.$route.query.ticket) {
      let copperDetails = {
        merchant: this.$route.query.merchant,
        location: this.$route.query.location,
        ticket: this.$route.query.ticket,
      }
      /* localStorage.setItem('copperDetails', JSON.stringify(copperDetails)) */
      this.SET_COPPER_DETAILS(copperDetails)
      if (!this.loggedIn) {
        this.toggleLoginModal()
      } else {
        this.toggleCopperLoyaltyModal()
      }
    }

    window.addEventListener('resize', () => {
      const innerWidth = window.innerWidth
      this.windowWidth = innerWidth
    })
  },
  mounted() {
    // if profileDropdown is open, close it when user clicks outside of it
    document.addEventListener('click', (e) => {
      if (this.profileDropdownIsVisible) {
        if (!this.$refs.profileDropdown.contains(e.target)) {
          this.profileDropdownIsVisible = false
        }
      }
    })
    setTimeout(() => {
      this.getCartPosition()
    }, 500)
  },
  beforeUnmount() {
    // remove event listener when component is destroyed
    document.removeEventListener('click', (e) => {
      if (this.profileDropdownIsVisible) {
        if (!this.$refs.profileDropdown.contains(e.target)) {
          this.profileDropdownIsVisible = false
        }
      }
    })
  },
}
</script>

<style src="../../../assets/css/Main/Layout/HeaderView.css" scoped></style>
