<template>
  <div
    :style="{
      'background-color': showLoader ? 'rgba(0, 0, 0, 0.5)' : 'var(--background-color)',
    }"
    class="loading"
  >
    <div class="loadingWrapper">
      <div
        :style="{
          'border-top-color': showLoader ? '#FFFFFF' : 'var(--passive-color)',
        }"
        class="loader mainLoader"
      ></div>
      <img v-if="this.branding" :src="getMenuLogo" alt="companyLogo" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['showLoader'],
  computed: {
    ...mapState(['branding']),
    getMenuLogo() {
      if (this.branding?.display_options.loading_logo_url) {
        return this.branding.display_options?.loading_logo_url
      }
      return this.branding ? this.branding.menu_logo_url : ''
    },
  },
}
</script>

<style src="../../assets/css/Loaders/LoaderView.css" scoped></style>
