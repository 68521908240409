export default {
  computed: {
    manageCardsIsVisible() {
      if (
        'display_manage_payments' in this.branding?.display_options &&
        !this.branding?.display_options.display_manage_payments
      ) {
        return false
      }
      return true
    },
    addressesIsVisible() {
      if (
        'display_addresses_in_profile' in this.branding?.display_options &&
        !this.branding?.display_options.display_addresses_in_profile
      ) {
        return false
      }
      return true
    },
  },
}
