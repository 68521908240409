const endpoints = {
  CUSTOMERS: {
    LOGIN: '/customers/login',
    LOGIN_WITH_SECURITY_CODE: '/customers/login-with-security-code',
    INFO: '/customers/me',
    REGISTER: '/customers/registration',
    RESET_PASSWORD: '/customers/password',
    CHANGE_PASSWORD: '/customers/password',
    SEND_VERIFICATION_CODE: '/customers/send-verify-phone',
    SEND_VERIFICATION_CODE_EMAIL: '/customers/send-verify-code',
    VERIFY_PHONE: '/customers/verify-phone',
    VERIFY_EMAIL: '/customers/verify',
    UPDATE_CUSTOMER: '/customers/:customerId',
    ADDRESSES: '/customers/:customerId/addresses',
    FAVORITE_ITEMS: '/customers/:customerId/favorite-items',
    ADD_FAVORITE_ITEM: '/customers/:customerId/favorite-items',
    REMOVE_FAVORITE_ITEM: '/customers/:customerId/favorite-items',
    SAVED_CARDS: '/customers/:customerId/saved-cards',
    LOYALTY_INFO: '/customers/:customerId/loyalty',
    ORDERS: '/customers/:customerId/orders',
    SAVE_ADDRESS: '/customers/:customerId/addresses',
    UPDATE_ADDRESS: '/customers/:customerId/addresses',
    DELETE_ADDRESS: '/customers/:customerId/addresses',
    SAVE_CARD: '/customers/:customerId/saved-cards',
    DELETE_CARD: '/customers/:customerId/saved-cards',
    DELETE_CUSTOMER: '/customers/:customerId',
    FAVORITE_STORES: '/customers/:customerId/favorite-stores',
    ADD_FAVORITE_STORE: '/customers/:customerId/favorite_stores',
    REMOVE_FAVORITE_STORE: '/customers/:customerId/favorite_stores',
    GUEST_LOGIN: '/customers/guest',
    UPDATE_GUEST_INFO: '/customers/:customerId',
    LOGOUT: '/customers/logout',
    REDEEM_IN_STORE: '/customers/:customerId/loyalty/redeem',
    COLLECT_IN_STORE: '/incoming/eef3acaf-170f-4270-8235-4b9e953eff20?synchronous=1',
    FOREIGN_AUTH: '/customers/login_by_foreign_auth',
    CHECKIN_FOR_ORDER: '/customers/:customerId/orders/checkin/:orderId',
    ORDERS_AVAILABLE_FOR_CHECKIN: '/customers/:customerId/orders/checkin',
  },
  MENUS: {
    MENUS: '/stores/:storeId/menus',
    ITEM: '/stores/:storeId/menus/:menuId/categories/:categoryId/items/:itemId',
  },
  STORES: {
    PAYMENT_METHODS: '/stores/:storeId/payment-methods',
    STORES: '/stores',
    STORE: '/stores/:storeId',
    STORE_NO_ID: '/stores',
    CONFIG: '/stores/configuration',
    BRANDING: '/theme-config/site/',
    APP_BRANDING: '/theme-config/app/',
  },
  ORDERS: {
    ORDER: '/orders/:orderId',
    ADD_TO_CART: '/orders/:orderId/items',
    REMOVE_FROM_CART: '/orders/:orderId/items/:itemId',
    CHANGE_QUANTITY: '/orders/:orderId/items',
    CREATE_ORDER: '/orders/new',
    UPDATE_ORDER: '/orders/new',
    UPDATE_ORDER_WITH_PUT: '/orders/:orderId',
    ADD_TIP: '/orders/:orderId',
    ADD_ORDER_NOTE: '/orders/:orderId',
    ADD_CHECKIN_INFO: '/orders/:orderId',
    CHECK_PAYMENT: '/orders/payment/:token',
    PAYMENT: '/orders/:orderId/payment',
    AVAILABLE_DISCOUNTS: '/orders/:orderId/available_discounts',
    REMOVE_DISCOUNT: '/orders/:orderId/discount',
    REDEEM_PROMO_CODE: '/orders/:orderId/discount',
    UPDATE_ITEM: '/orders/:orderId/items',
    GOOGLE_PAY: '/orders/:orderId/payment_response',
    APPLE_PAY: '/orders/:orderId/payment_response',
    APPLE_PAY_SESSION: '/payment/get_apple_pay_session',
  },
}

export default endpoints
