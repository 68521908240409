import axios from 'axios'
import endpoints from './endpoints.js'
import { createRequestData, url } from './constants.js'

// get order data
const getOrder = async (orderId) => {
  let requestData = createRequestData(endpoints.ORDERS.ORDER.replace(':orderId', orderId), 'GET')
  return await axios.post(url, requestData)
}

// add item to cart
const addItemToCart = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.ADD_TO_CART.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// remove item from cart
const removeItemFromCart = async (orderId, itemId) => {
  let requestData = createRequestData(
    endpoints.ORDERS.REMOVE_FROM_CART.replace(':orderId', orderId).replace(':itemId', itemId),
    'DELETE'
  )
  return await axios.post(url, requestData)
}

// change item quantity
const changeItemQuantity = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.CHANGE_QUANTITY.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// create order
const createOrder = async (payload) => {
  let requestData = createRequestData(endpoints.ORDERS.CREATE_ORDER, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// update order
const updateOrder = async (payload) => {
  let requestData = createRequestData(endpoints.ORDERS.UPDATE_ORDER, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// update order with PUT
const updateOrderWithPut = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.UPDATE_ORDER_WITH_PUT.replace(':orderId', orderId), 'PUT')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// add tip to order
const addTip = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.ADD_TIP.replace(':orderId', orderId), 'PUT')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// add order note
const addOrderNote = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.ADD_ORDER_NOTE.replace(':orderId', orderId), 'PUT')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// add checkin info
const addCheckinInfo = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.ADD_CHECKIN_INFO.replace(':orderId', orderId), 'PUT')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// check payment
const checkPayment = async (token, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.CHECK_PAYMENT.replace(':token', token), 'GET')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// start payment
const startPayment = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.PAYMENT.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// get available discounts
const getAvailableDiscounts = async (orderId) => {
  let requestData = createRequestData(endpoints.ORDERS.AVAILABLE_DISCOUNTS.replace(':orderId', orderId), 'GET')
  return await axios.post(url, requestData)
}

// remove discount from order
const removeDiscount = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.REMOVE_DISCOUNT.replace(':orderId', orderId), 'DELETE')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// redeem promo code
const redeemPromoCode = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.REDEEM_PROMO_CODE.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// update item on cart
const updateItemOnCart = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.UPDATE_ITEM.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// google pay
const googlePay = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.GOOGLE_PAY.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// apple pay
const applePay = async (orderId, payload) => {
  let requestData = createRequestData(endpoints.ORDERS.APPLE_PAY.replace(':orderId', orderId), 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

// apple pay session
const applePaySession = async (payload) => {
  let requestData = createRequestData(endpoints.ORDERS.APPLE_PAY_SESSION, 'POST')
  payload = { ...payload, ...requestData }
  return await axios.post(url, payload)
}

export default {
  getOrder,
  addItemToCart,
  removeItemFromCart,
  changeItemQuantity,
  createOrder,
  updateOrder,
  updateOrderWithPut,
  addTip,
  addOrderNote,
  addCheckinInfo,
  checkPayment,
  startPayment,
  getAvailableDiscounts,
  removeDiscount,
  redeemPromoCode,
  updateItemOnCart,
  googlePay,
  applePay,
  applePaySession,
}
