export default {
  methods: {
    getAddressComponents(addressData, creditCard = false, checkStreetNumber = false) {
      // get the address components from the address data
      this.addressSelected = true
      if (checkStreetNumber) {
        // check if street_number is present
        var street_number = addressData.address_components.find((component) => component.types[0] === 'street_number')
        let requiresAddress = this.selectedServiceData
          ? this.selectedServiceData.requires_address
          : this.selectedService.requires_address
        if (!street_number && requiresAddress) {
          this.validAddressError = 'Please enter a valid address'
          return
        }
        this.validAddressError = null
      }
      var ShouldBeComponent = {
        street_number: ['street_number'],
        postal_code: ['postal_code', 'postal_code_prefix'],
        address1: ['street_address', 'route'],
        city: [
          'locality',
          'sublocality',
          'sublocality_level_1',
          'sublocality_level_2',
          'sublocality_level_3',
          'sublocality_level_4',
        ],
        state_code: ['administrative_area_level_1'],
      }

      var address = {
        street_number: '',
        postal_code: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        phone: '',
        return_details: 'test',
      }
      addressData.address_components.forEach((component) => {
        for (var shouldBe in ShouldBeComponent) {
          if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
            if (shouldBe === 'state_code') {
              address[shouldBe] = component.short_name
            } else {
              address[shouldBe] = component.long_name
            }
          }
        }
      })
      if (creditCard) {
        this.creditCardData.address1 = address.street_number + ' ' + address.address1
        this.creditCardData.city = address.city
        this.creditCardData.state = address.state_code
        this.creditCardData.postal_code = address.postal_code
      }
      this.addressData = address
    },
    getRadarAddressComponents(addressData, creditCard = false, checkStreetNumber = false) {
      if (!addressData) return
      // get the address components from the address data
      this.addressSelected = true
      this.address = addressData.formattedAddress
      if (checkStreetNumber) {
        // check if street_number is present
        var street_number = addressData.number
        let requiresAddress = this.selectedServiceData
          ? this.selectedServiceData.requires_address
          : this.selectedService.requires_address
        if (!street_number && requiresAddress) {
          this.validAddressError = 'Please enter a valid address'
          return
        }
        this.validAddressError = null
      }

      var address = {
        street_number: addressData.number,
        postal_code: addressData.postalCode,
        address1: addressData.street,
        address2: '',
        city: addressData.city,
        state_code: addressData.stateCode,
        phone: '',
        return_details: 'test',
      }

      if (creditCard) {
        this.creditCardData.address1 = address.street_number + ' ' + address.address1
        this.creditCardData.city = address.city
        this.creditCardData.state = address.state_code
        this.creditCardData.postal_code = address.postal_code
      }
      this.addressData = address
    },
  },
}
