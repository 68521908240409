<template>
  <input type="text" ref="autocompleteInput" :placeholder="placeholder" />
</template>

<script>
import googleMapsLoader from '@/mixins/googleMapsLoader'

export default {
  mixins: [googleMapsLoader],
  props: ['placeholder', 'requiresAddress'],
  watch: {
    requiresAddress() {
      this.resetInput()
      this.createAutoComplete()
    },
  },
  methods: {
    createAutoComplete() {
      this.loader.load().then(async () => {
        const input = this.$refs.autocompleteInput
        let type = this.requiresAddress ? ['address'] : ['geocode']
        const options = {
          types: type,
          componentRestrictions: { country: ['us', 'ca'] },
          fields: ['address_components', 'geometry'],
        }
        const autocomplete = new window.google.maps.places.Autocomplete(input, options)
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()
          this.$emit('placeChanged', place)
        })
        input.addEventListener('input', () => {
          this.$emit('input', input.value)
        })
      })
    },
    resetInput() {
      this.$refs.autocompleteInput.value = ''
    },
    focusInput() {
      this.$refs.autocompleteInput.focus()
    },
    addInputValue(value) {
      this.$refs.autocompleteInput.value = value
    },
  },
  mounted() {
    this.createAutoComplete()
  },
}
</script>

<style scoped></style>
