<template>
  <div class="selector">
    <div :style="{ cursor: elements.length == 1 ? 'default' : loading ? 'not-allowed' : 'pointer' }" class="elements">
      <div
        v-if="elements.length > 1"
        class="active"
        :style="{ left: leftAmount, width: 100 / elements.length + '%' }"
      ></div>
      <p
        v-for="(element, index) in elements"
        :key="index"
        @click="change(element)"
        :style="{
          'pointer-events': elements.length == 1 || loading ? 'none' : 'auto',
          'background-color': elements.length == 1 ? 'var(--gray-color)' : '',
        }"
        :class="{
          activeText: selectedId == element[elementId] && elements.length != 1,
        }"
      >
        {{ element[elementName] }}
        {{ elements.length == 1 ? 'Only' : '' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['elements', 'elementId', 'selectedId', 'elementName', 'loading'],
  computed: {
    leftAmount() {
      let elementsLength = this.elements.length
      let multiplier = 100 / elementsLength
      let selectedIndex = this.elements.findIndex((element) => element[this.elementId] == this.selectedId)
      let leftAmount = multiplier * selectedIndex
      return leftAmount + '%'
    },
  },
  methods: {
    change(element) {
      this.$emit('change', element)
    },
  },
}
</script>

<style src="../../assets/css/Elements/SegmentedControl.css" scoped></style>
