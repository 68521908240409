<template>
  <div id="googleButton"></div>
</template>

<script>
import customersApi from '@/api/customersApi.js'

import { mapState, mapMutations } from 'vuex'

export default {
  emits: ['getCustomerInfo'],
  computed: {
    ...mapState(['branding']),
  },
  methods: {
    ...mapMutations(['SET_COOKIES']),
    handleCredentialResponse(response) {
      this.$emit('showLoader')
      let payload = {
        provider: 'google',
        auth_info: {
          access_token: response.credential,
        },
      }
      customersApi
        .foreignAuth(payload)
        .then((response) => {
          localStorage.setItem('customerId', response.data.data.customer_id)
          this.SET_COOKIES(response.data.cookies)
          this.$emit('getCustomerInfo')
        })
        .catch((error) => {
          console.log(error)
          this.$emit('ssoError')
        })
    },
  },
  mounted() {
    setTimeout(() => {
      /* global google */
      google.accounts.id.initialize({
        client_id: this.branding?.sso?.google?.client_id,
        callback: this.handleCredentialResponse,
        auto_select: true,
      })
      google.accounts.id.renderButton(document.getElementById('googleButton'), {
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        width: document.getElementById('googleButton')?.offsetWidth,
        logo_alignment: 'center',
      })
    }, 500)
  },
}
</script>
